<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex-grow">
                <div class="vx-row">
                    <div style="display: inline-block" class="vx-col md:w-1/5 sm:w-auto mr-6">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                                  :options="organizationBranchOptions">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div style="display:inline-block; width: 30% !important;" class="vx-col md:w-1/3 sm:w-auto ml-6">
                        <label class="vs-input--label">Tài sản</label>
                        <v-select class="" v-model="selectedDevice" :clearable="false"
                                  :options="deviceOptions">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div class="vx-col">
                        <label class="vs-input--label">Thời gian</label>
                        <div class="flex-grow">
                            <date-range-picker
                                ref="picker"
                                :locale-data="{format: 'dd/mm/yyyy' }"
                                :opens="'right'"
                                @update="dateRangePicker"
                                v-model="dateRange">
                            </date-range-picker>
                        </div>
                    </div>
                    <div class="vx-col md:w-1/6 w-full mt-6">
                        <vs-button
                            @click="loadAssignedDevice"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="">
                            Lọc
                        </vs-button>
                    </div>

                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow"></div>
                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="deviceData"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />

                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="deviceData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/DeviceHistoryCellRendererActions.vue"
import moment from 'moment';
import Pagination from "../../ui-elements/Pagination.vue"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Datepicker from 'vuejs-datepicker';
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
        Datepicker,
        Pagination,
        DateRangePicker,
        flatPickr
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Thiết bị',
                    field: 'displayDevice',
                    width: 280
                },
                {
                    headerName: 'Số Serial',
                    field: 'serialNumber',
                    width: 150,
                },
                {
                    headerName: 'Nhân viên',
                    field: 'displayEmployee',
                    width: 180,
                },
                {
                    headerName: 'Hành động',
                    field: 'displayType',
                    width: 120,
                },
                {
                    headerName: 'Thời gian',
                    field: 'displayCreatedAt',
                    width: 150,
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            deviceData: [],
            organizationBranchOptions: [],
            deviceOptions: [],
            selectedOrganizationBranchValue: null,
            selectedDeviceValue: null,
            fromDate: null,
            toDate: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setDate(date.getDate() - 6);
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateAssignedDevice'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_ASSIGNED_DEVICE', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
                this.loadDevice()
            }
        },

        selectedDevice: {
            get() {
                return {
                    label: this.deviceString(this.selectedDeviceValue),
                    value: this.selectedDeviceValue
                }
            },
            set(obj) {
                this.selectedDeviceValue = obj.value
                // this.loadAssignedDevice()
            }
        }
    },
    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            console.log(this.dateRange);
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        deviceString(id) {
            let device = this.deviceOptions.find(x => x.value === id);
            return (device && device.label) || 'Chưa xác định';
        },
        addDevice() {
            this.$router.push(`/user/assign-device?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
        loadDevice() {
            if (!this.selectedOrganizationBranchValue) return;
            let searchInfo = {
                deviceType: 0,
                organizationBranchId: this.selectedOrganizationBranchValue
            }
            this.$vs.loading();
            this.$crm.post('/device/find-by-condition', searchInfo).then((response) => {
                this.deviceOptions = response.data.map(item => {
                    return {
                        value: item.id,
                        label: item.type + " " + item.model + (item.serialNumber ? " - " + item.serialNumber : "")
                    }
                });
                this.deviceOptions.unshift({value: null, label: 'Tất cả'})
                let deviceId = this.$route.query.id;
                let index = 0;
                if (deviceId) {
                    index = this.deviceOptions.findIndex(item => item.value == deviceId)
                }
                this.selectedDevice = this.deviceOptions[index > -1 ? index : 0];
                this.$vs.loading.close();
                this.loadAssignedDevice()
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        loadAssignedDevice() {
            if (!this.selectedOrganizationBranchValue) return;
            let searchInfo = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                deviceId: this.selectedDeviceValue,
                startDate: this.fromDate ? moment(this.fromDate).set({hour: 0, minute: 0}) : null,
                endDate: this.toDate ? moment(this.toDate).set({hour: 23, minute: 59}) : null,
                deviceType: 0
            }
            this.$vs.loading();
            this.$crm.post('/device/find-device-history', searchInfo).then((response) => {
                this.deviceData = response.data.map(item => {
                    item.displayDevice = item.type + " " + item.brand + " " + item.model;
                    item.displayType = 'Không xác định';
                    if (item.historyType == 1) item.displayType = 'Nhập';
                    if (item.historyType == 2) item.displayType = 'Xuất';
                    if (item.historyType == 3) item.displayType = 'Cấp phát';
                    if (item.historyType == 4) item.displayType = 'Thu hồi';
                    item.displayEmployee = item.employeeCode + " - " + item.fullName;
                    item.displayCreatedAt = moment(item.createdDate).format("DD/MM/YYYY")
                    return item;
                });
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
